import axios from "axios";

const SERVER_URL = `https://plan-api.gatheringuelph.com`;
// const SERVER_URL = `http://localhost:7000`;

export const replaceASCII=(str)=>{
    let index= str.indexOf("&#");
    let lastIndex = str.lastIndexOf("&#");
    let count = index;
    if(index>0){
        while(str[count] !== ";"){
            count ++;
        }
 
        let asciiChars = str.substring(
            index+2, 
            str.indexOf(";")
        );
        let charsToReplace = str.substring(
            index, 
            count
        );
        let temp = str.replace(charsToReplace, String.fromCharCode(asciiChars));

       if(index !== lastIndex){
         let leftOver = temp.replace(";","");
         return replaceASCII(leftOver);
       }
         return temp.replace(";","");
    }
    return str;
}

export const getTagsAsync = async ()=>
{
    const tags = await axios.get(`${SERVER_URL}/api/tags`);
    return tags;
};

export const searchAsync = async (tags)=>{

    const request = {
        data: {
            tags: tags
        }
    };
    return await axios.post(`${SERVER_URL}/api/places/search`,request, (data)=>{
        return data;
    })
}

export const sendEmailAsync = async({email, content})=>{
    const request={
        data: {
            message: {
                to: [
                    {
                        "email": email
                    }
                ],
                from_email:"itinerary@gatheringuelph.com",
                template_content: content
            }
        }
    }
    return await axios.post(`${SERVER_URL}/api/messages`,request, (data)=>{
        return data;
    });
}

export const getEventsAsync = async({startDate, endDate})=>{
    const request={
        data: {
           startDate,
           endDate
        }
    }
    return await axios.post(`${SERVER_URL}/api/events`,request, (data)=>{
        return data;
    });
}