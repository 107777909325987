import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Grid, ListItem, List } from "@mui/material";
import { MultiSelect } from '../components/MultiSelect';
import { tagsData } from '../data';
import { tagsQueryAsync, thingsToDoFormState, stepStatusState, personalInfoFormState, stepState } from "../state";


const PreferencesForm = () => {
    const [tags, setTags] = React.useState([]);
    const thingsToDo = useRecoilValue(thingsToDoFormState);
    const personalInfo = useRecoilValue(personalInfoFormState);
    const tagsFromDb = useRecoilValue(tagsQueryAsync);
    //Check which parent type is true, create separate multiSelect

    const getTags = async () => {
        if (!tagsData.length > 0) {
            setTags(tagsFromDb);
        } else {
            console.log("did not make get all tags call");
            setTags(tagsData);
        }

    }

    const isOneSelected = () => {
        return thingsToDo.some(_ => _.isChecked);
    }
    const updateStepStatus = () => {
        return "Please make a selection in the previous step.";
    }

    const getFilteredTags = (thing) => {
        return tags.filter(_ => {
            if (thing === "food_drink") {
                return _.description === "eat";
            }
            if (thing === "see_do") {
                return _.description === "experience";
            }
            if (thing === "stay") {
                return _.description === thing;
            }
        });
    }

    useEffect(() => {
        getTags();
    }, [tags]);
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
            <List>
                {isOneSelected() ? (thingsToDo.map((item, i) => {
                    if (item.isChecked && item.name !== "events") {
                        return (
                            <ListItem key={i} sx={{ display: "grid" }}>
                                <MultiSelect
                                    id={item.id}
                                    heading={item.text}
                                    tags={getFilteredTags(item.name)}
                                />
                            </ListItem>
                        )
                    }
                }
                )) : (updateStepStatus())}
            </List>
        </Grid>);
}

export default PreferencesForm;