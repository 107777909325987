import React from 'react';
import { useRecoilState } from 'recoil';
import { TextField, Grid, FormControl } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { personalInfoFormState  } from "../state";


const PersonalInfoForm = () => {
    const [, setPersonalInfo] = useRecoilState(personalInfoFormState);
    const [error, setError] = React.useState({});
    const handleChange = (event) => {
        const {
            target: { value, name },
        } = event;

        if(!event.target.validity.valid){
            let updateValidity ={[name]:true};
            setError((obj)=>({...obj, ...updateValidity }));
        }else{
            let updateValidity ={[name]:false};
            setError((obj)=>({...obj, ...updateValidity }));
        }

        let updateVal = {[name]:value};
        setPersonalInfo((obj)=>({
            ...obj,
            ...updateVal
        }));
    };
    const handleDateChange=(val)=>{
        let updateVal = {"dateOfArrival": `${val.format('YYYY-MM-DD')}`};
        setPersonalInfo((obj)=>({
            ...obj,
            ...updateVal
        }));
    }
    const handleDeptDateChange=(val)=>{
        let updateVal = {"dateOfDeparture": `${val.format('YYYY-MM-DD')}`};
        setPersonalInfo((obj)=>({
            ...obj,
            ...updateVal
        }));
    }

    return (
        <Grid container direction="column" justifyContent="center" alignItems="stretch">
           <FormControl>
                <TextField id="firstName" name="firstName" aria-label="First Name" label="First Name" variant="outlined"
                    sx={{ m: 1 }}
                    onChange={handleChange}
                    required
                    helperText={error["firstName"]? "Please use letters and spaces only for first name":"Enter your first name"}
                    error={error["firstName"]}
                    inputProps={{
                        pattern: "[A-Za-z ]+",
                      }}
                />

            </FormControl>
            <FormControl>
                <TextField id="lastName" name="lastName" aria-label="Last Name" variant="outlined" label="Last Name"
                    sx={{ m: 1 }} 
                    onChange={handleChange}
                    required
                    helperText={error["lastName"]? "Please use letters and spaces only for last name":"Enter your last name"}
                    error={error["lastName"]}
                    inputProps={{
                        pattern: "[A-Za-z ]+",
                      }}
                    />
            </FormControl>

            <FormControl>
                <TextField id="emailAddress" name="email" aria-label="Email" label="Email" variant="outlined"
                    sx={{ m: 1 }}
                    onChange={handleChange}
                    required
                    helperText={error["email"]? "Please enter a valid email address":"The email address where the itinerary will be sent"}
                    error={error["email"]}
                    inputProps={{
                       type: "email"
                    }}
                    />
            </FormControl>

            <FormControl>
                <DatePicker id="dateOfArrival" name="dateOfArrival" variant="outlined" aria-label="Date of arrival" label="Date of arrival"
                    sx={{ m: 1 }}
                    slotProps={{ textField: { helperText: "Enter the estimated date of arrival" } }} 
                    onChange={handleDateChange}/>
            </FormControl>
            <FormControl>
                <DatePicker id="dateOfDeparture" name="dateOfDeparture" variant="outlined" aria-label="Date of departure" label="Date of departure"
                    sx={{ m: 1 }}
                    slotProps={{ textField: { helperText: "Enter the estimated date of departure" } }} 
                    onChange={handleDeptDateChange}/>
            </FormControl>
        </Grid>
    );
}
export default PersonalInfoForm;