import * as React from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';
import PersonalInfoForm from './forms/PersonalInfoForm';
import ThingsToDoForm from './forms/ThingsToDoForm';
import PreferencesForm from './forms/PreferencesForm';
import Recommendations from './forms/Recommendations';
import Results from './forms/Results';
import { personalInfoFormState, selectedTagListState, recommendationsState, stepStatusState, thingsToDoFormState, selectedRecommendationsState } from "./state";
import { searchAsync, sendEmailAsync, getEventsAsync, validatePersonalInformation } from './util';
import { toast } from 'react-toastify';



const _renderStepContent = (step) => {
    switch (step) {
        case 0:
            return <PersonalInfoForm />;
        case 1:
            return <ThingsToDoForm />;
        case 2:
            return <Suspense fallback={<Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>}><PreferencesForm /></Suspense>;
        case 3:
            return <Suspense fallback={<Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>}><Recommendations /></Suspense>;
        default:
            return <Results />;
    }
}

export default function FormStepper() {
    const [steps] = React.useState(['Enter Personal Info', 'Select things to do', 'Select Preferences', 'Select Recommendations', 'Review Results']);
    const [isMobile, setIsMobile] = React.useState(!!navigator.maxTouchPoints && window.innerWidth < 768 ? true : false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [hasOnlyEvents, setOnlyEvents] = React.useState(false);
    const [, setRecommendations] = useRecoilState(recommendationsState);
    const resetRecommendations = useResetRecoilState(recommendationsState);
    const resetSelectTags = useResetRecoilState(selectedTagListState);
    const resetSelectedRecommendations = useResetRecoilState(selectedRecommendationsState);
    const selectedTags = useRecoilValue(selectedTagListState);
    const [personalInfo, setPersonalInfo] = useRecoilState(personalInfoFormState);
    const stepStatus = useRecoilValue(stepStatusState);
    const [error, setError] = React.useState(false);
    const thingsToDo = useRecoilValue(thingsToDoFormState);
    const selectedRecommendations = useRecoilValue(selectedRecommendationsState);

    const getResults = (currentActiveStep) => {
        //If nothing is checked stay in the current step
        const isChecked = thingsToDo.some(_ => _.isChecked);
        if (!isChecked) {
            setActiveStep(currentActiveStep - 1);
        }
        //Get events if checked
        if (thingsToDo[0].isChecked) {

            if (!personalInfo.dateOfArrival || !personalInfo.dateOfDeparture) {
                //error
                toast.error("Date of Arrival or Departure cannot be empty. Starting over........", {
                    position: "bottom-right",
                    onClose: () => handleReset()
                });
            } else {
                getEventsAsync({ startDate: personalInfo.dateOfArrival, endDate: personalInfo.dateOfDeparture }).then((d) => {
                    setRecommendations((oldResults) => {
                        return {
                            ...oldResults,
                            '0': d.data
                        }
                    });
                });
            }
        }

        Object.keys(selectedTags).forEach((item) => {
            if (selectedTags[item].length > 0) {
                searchAsync(selectedTags[item]).then((d) => {
                    setRecommendations((oldResults) => {
                        return {
                            ...oldResults,
                            [item]: d.data.places
                        }
                    });
                });
            }
        });
    }

    const sendEmail = () => {
        let content = [];
        if (!personalInfo.email) {
            //error
            toast.error("Email required for sending itinerary. Starting over........", {
                position: "bottom-right",
                onClose: () => handleReset()
            });
        }
        for (let obj in selectedRecommendations) {
            content = content.concat(selectedRecommendations[obj]);
        }
        sendEmailAsync({ email: personalInfo.email, content: selectedRecommendations }).then((res) => {
            console.log("Step completed.......send email to: ", res.status);
            setTimeout(()=>{
                toast.success("Thank you for planning your itinerary! Redirecting...", {
                    position: "bottom-right",
                    onClose: () => handleReset()
                });
            },4000);
            
        });

    }

    const isStepOptional = (step) => {
        return step === -1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const validatePersonalInformation = () => {
        if (!personalInfo.firstName || !personalInfo.lastName || !personalInfo.email) {
            toast.error("A valid First Name, Last Name and Email are required", {
                position: "bottom-right",
                onClose: () => handleReset()
            });
        }
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (activeStep === 0) { //run validation on personal information
            validatePersonalInformation();
        }
        if (activeStep === 1) {
            updateActiveStep(thingsToDo);
        }
        if (activeStep === 2) { //Get preferences from the selection
            getResults(activeStep);
        }

        if (activeStep === 4) {
            sendEmail();
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        if (activeStep === 3) { //Reset state if preferences back button clicked
            resetRecommendations();
            resetSelectedRecommendations();
            resetSelectTags();
        }
        if (hasOnlyEvents && activeStep === 3) { //if only events step back 2 steps
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        resetRecommendations();
        resetSelectedRecommendations();
        resetSelectTags();
        setPersonalInfo({});
    };

    const updateActiveStep = (thingsToDo) => {
        let eventThings = thingsToDo.map(t => { return t.isChecked }).findLastIndex(_ => _ === true);
        if (eventThings === 0) {
            // might want to skip the step
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setOnlyEvents(true);
            getResults(activeStep);
        }
    }
    const detect = () => {
        setIsMobile(!!navigator.maxTouchPoints && window.innerWidth < 768? true : false);
    }
    React.useEffect(() => {
        window.addEventListener("resize", detect);
        return () => window.removeEventListener("resize", detect);
    }, [isMobile]);

    return (
        <Box sx={{ mt: 5 }}>
            <Typography //move to bottom out of the menu bar
                variant="h3"
                noWrap
                component="h3"
                sx={{
                    mr: 0,
                    display: { xs: 'none', md: 'flex' },
                    flexGrow: 1,
                    fontWeight: 900,
                    letterSpacing: '0.0rem',
                    color: '#7b4183',
                    justifyContent: 'center'
                }}
                align='center'
            >
                Plan Your Trip
            </Typography>
            <Stepper activeStep={activeStep} sx={{ mt: 2, mb: 5 }} orientation={isMobile ? 'vertical' : 'horizontal'}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    // if (isStepOptional(index)) {
                    //     labelProps.optional = (
                    //         <Typography variant="caption">Optional</Typography>
                    //     );
                    // }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography variant="body1" sx={{ mt: 2, mb: 1, textAlign: "center" }}>
                        Thank you for planning your itinerary with us! <br></br>
                        Your selections have been emailed to you :)
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>

                    {_renderStepContent(activeStep)}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}
                        <Button color="secondary" onClick={handleReset} sx={{ mr: 5 }}>
                            Start Over
                        </Button>

                        <Button onClick={handleNext} disabled={stepStatus[activeStep] === "disable" ? true : false}>
                            {activeStep === steps.length - 1 ? 'Complete' : 'Next'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}
