import { atom, selector } from "recoil";
import { getTagsAsync } from './util';

export const stepStatusState = atom({
    key: "stepStatus",
    default: { 0: "enable", 1: "enable", 2: "enable" }
});

export const personalInfoFormState = atom({
    key: "personalInfoForm",
    default: {}
});
const defaultThingsTodo = [
    { id: 0, name: 'events', text:'Events',isChecked: false, primaryColor: "#41B7B3", secondaryColor: "#367F80" },
    { id: 1, name: 'food_drink',text:'Food & Drink', isChecked: false, primaryColor: "#FCAF17", secondaryColor: "#F26921" },
    { id: 2, name: 'see_do', text:'See & Do', isChecked: false, primaryColor: "#B55196", secondaryColor: "#7B4183" },
    { id: 3, name: 'stay', text: 'Stay', isChecked: false,primaryColor: "#41B7B3", secondaryColor: "#367F80" }
];

export const thingsToDoFormState = atom({
    key: "thingsToDoForm",
    default: defaultThingsTodo
});

export const selectedTagListState = atom({
    key: "selectedTags",
    default: { 0: [], 1: [] ,2: [] , 3: [] }
});

export const recommendationsState = atom({
    key: "recommendations",
    default: { 0: [] , 1: [],2: [] , 3: [] }
});

export const selectedRecommendationsState = atom({
    key: "selectedRecommendations",
    default: {}
});

export const stepState = atom({
    key: "stepState",
    default: 0
});

export const tagsQueryAsync = selector({
    key: 'tags',
    get: async ({ get }) => {
        return (await getTagsAsync()).data;
    },
});