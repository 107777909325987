

import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Box, Typography, Checkbox } from "@mui/material";
import { thingsToDoFormState } from "../state";


const ToDoButton = ({ primaryColor, secondaryColor, text, value, checked, onClick }) => {
    const [, setThingsToDo] = useRecoilState(thingsToDoFormState);
    const [isChecked, setIsChecked] = useState(false);
    const [isMobile, setIsMobile] = React.useState(!!navigator.maxTouchPoints ? true : false);
    const addThingsToDo =(value, isChecked)=>{
        setThingsToDo((oldIsChecked)=>{
            return oldIsChecked.map(item=>{
                if(item.name === value){
                    return {
                        ...item,
                        name:value,
                        isChecked
                    }
                }else{
                    return item;
                }
            });
        });
    }

    useEffect(()=>{
      addThingsToDo(value, isChecked);
    },[isChecked]);
    return (
        <Box sx={{
            height: {
                xs: "11vh",
                sm: 200,
                md: 300
            },
            width: {
                xs: "100%",
                sm: 200,
                md: 300
            },
            justifyContent: "center",
            justifyItems: "center",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flexFlow: "column",
            ml: 1,
            "&:hover": {
                backgroundColor: secondaryColor
            },
            mb: isMobile? 1 :0,
            backgroundColor: isChecked? secondaryColor: primaryColor,
            borderColor: isChecked? "#000" : "#fff",
            border: isChecked? 4 : 0,
            borderRadius: isMobile?"25px 5px 25px 25px" :"50px 5px 50px 50px"
        }} value={value}
        onClick={()=>{setIsChecked(c=>!c)}}>

                <Typography variant="h4" sx={{
                    textTransform: "none", fontSize: {
                        xs: 18,
                        sm: 20,
                        md: 25,
                        lg: 30
                    },
                    ".MuiTypography-root":{
                        mt: "50%"
                    }
                }}>
                    {text}
                </Typography>
                {/* <Checkbox id={text+1} sx={{
                    ".MuiSvgIcon-root": {
                        display: "none"
                    }
                }} checked={isChecked} onChange={()=>setIsChecked(c=>!c)}/> */}
        </Box>
    );
}
export default ToDoButton;