import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { CircularProgress, Grid } from "@mui/material";
import { recommendationsState, selectedTagListState, thingsToDoFormState } from "../state";
import ListResult from '../components/ListResult';


const Recommendations = () => {
    const recommendations = useRecoilValue(recommendationsState);
    const selectedTags = useRecoilValue(selectedTagListState);
    const thingsToDo = useRecoilValue(thingsToDoFormState);
    const hasTags =()=>{
       let _tags=[];
        for(let tag in selectedTags){
            _tags.push(selectedTags[tag].length);
       }
       return _tags.some(_=>_ >0);
    }

    const hasRecommendations = ()=>{
        let _recommendations=[];
        for(let recommendation in recommendations){
            _recommendations.push(recommendations[recommendation].length);
       }
       return _recommendations.some(_=>_ >0);
    }

    const getTitleThingsToDo=(index)=>{
         if(thingsToDo[index].isChecked === true){
            return  thingsToDo[index].text;
        }
    }

    React.useEffect(() => {
        
    }, [recommendations]);
    return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        {hasTags() && hasRecommendations()?
        Object.keys(recommendations).map((item, i)=>{
            if (recommendations[item].length > 0) {
                return (<ListResult title={getTitleThingsToDo(i)} data={recommendations[item]} key={i}/>);
            }
        }):(thingsToDo[0].isChecked?
        (recommendations['0'].length === 0? "No recommendations found.": (<ListResult title={getTitleThingsToDo(0)} data={recommendations['0']||[]} key={0}/>)) //If we have events
        : <CircularProgress/>)
    }
    </Grid>)
}


export default Recommendations;