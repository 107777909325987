import * as React from 'react';
import { useRecoilState } from 'recoil';
import { replaceASCII } from '../util';
import PlaceCard from './PlaceCard';
import { ListItem, List, Link, Typography, Checkbox, Grid, Divider } from "@mui/material";
import { selectedRecommendationsState } from "../state";


const ListResult = ({ title, data, key }) => {
    const [checked, setChecked] = React.useState([]);
    const [selectedRecommendations, setSelectedRecommendations] = useRecoilState(selectedRecommendationsState);


    const handleToggle = (value, data, title) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);

        setSelectedRecommendations((currentRecommendations) => {
            return {
                ...currentRecommendations,
                [title]: [...newChecked].map(_ => { return { name: data[_].name, slug: data[_].slug, idx: value } })
            }
        });
    };

    const isChecked = (place) => {
        if (!selectedRecommendations[title]) {
            return false
        }
        return selectedRecommendations[title].filter(_ => {
            return _.name === place.name
        })[0];
    }

    return (
        <Grid container spacing={{ xs: 1, md: 2 }} sx={{ display: "flex" }} maxWidth={"md"} key={key}>
            <Grid item xs={12}>
                <Divider sx={{
                    '&::before, &::after': {
                        borderColor: '#B55196',
                        borderWidth: 5,
                    }
                }} >
                    <Typography variant="h4" color={"#000"} sx={{ mt: 2, mb: 1 }} align='center' key={title + Date.now()}>
                        {title}
                    </Typography>
                </Divider>
                <Typography variant="caption" sx={{ color: "#F26921", justifyContent: 'center', display: 'flex' }}>
                    Make your selection(s)
                </Typography>
            </Grid>
            {data.map((place, i) => {
                return (
                    <Grid item direction="column" aria-label="Recommendation list item" xs={12} md={6}>
                        <PlaceCard key={i} name={place.name} imageSrc={place.img} description={place.description} slug={place.slug} address={place.address} tags={place.onlyTags}>
                            <Checkbox
                                aria-label="Recommendation item checkbox"
                                key={i}
                                checked={checked.indexOf(i) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': `checkbox-list-label-${i}` }}
                                onClick={handleToggle(i, data, title)}
                            />
                            <Typography variant="body1" sx={{ color: "#F26921", justifyContent: 'center', display: 'flex' }}>
                                {checked.indexOf(i) !== -1 ? "Added to selection" : "Add to selection"}
                            </Typography>
                        </PlaceCard>
                    </Grid>
                );
            })}
        </Grid>)
}

export default ListResult;