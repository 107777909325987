import React from 'react'
import { useRecoilState } from 'recoil';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, FormHelperText, Box, Chip, Typography } from "@mui/material";
import { selectedTagListState } from "../state";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};




export const MultiSelect = ({ id, heading, tags }) => {
    const [selectedTag, setTag] = React.useState({[id]:[]});
    const [, setSelectedTags] = useRecoilState(selectedTagListState);
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setTag(
            // On autofill we get a stringified value.
           {[id] : typeof value === 'string' ? value.split(',') : value}
        );
        setSelectedTags((oldSelectedTags)=>{
            // On autofill we get a stringified value.
            return { ...oldSelectedTags,
                [id]:typeof value === 'string' ? value.split(',') : value}
        });
    };
    return (
        <>
            <FormControl>
                <Typography variant="h3" align='center' sx={{
                    textTransform: "none", fontSize: {
                        xs: 20,
                        sm: 20,
                        md: 25,
                        lg: 30
                    }
                }}>
                    {heading}
                </Typography>
            </FormControl>
            <FormControl sx={{ m: 1, width: '20rem' }}>
                <InputLabel id="preferenceTags-label">Preferences</InputLabel>
                <Select
                    aria-label="Preferences select"
                    labelId={`${id}-preferenceTags-label`}
                    id={`${id}-preferenceTags`}
                    multiple
                    value={selectedTag[id]}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>)}
                    MenuProps={MenuProps}
                >
                    {tags.map((tag) => (
                        <MenuItem key={tag.id} value={tag.name}>
                            <Checkbox aria-label="Preferences checkbox" checked={selectedTag[id].indexOf(tag.name) > -1} />
                            <ListItemText aria-label="Preferences text" primary={tag.name} />
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Select upto 3 preferences by clicking the check mark</FormHelperText>
            </FormControl></>
    );
}