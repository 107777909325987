import React from 'react'
import './App.css';
import MenuBar from './components/MenuBar';
import FormStepper from './FormStepper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from '@emotion/react';
import { createTheme, Box } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from "react-ga4";

ReactGA.initialize("G-JHHFWFZJWR");

const theme = createTheme({
    typography: {
        fontFamily: [
            'museo-sans',
            'sans-serif',
        ].join(','),
        fontSize: 15,
        fontWeightRegular: 100,
        p: {
            fontSize: 12
        },
        h4: {
            color: "#fff"
        }
    },
    palette: {
        primary: {
            light: "#e3f2fd",
            main: '#41B7B3',
        },
        secondary: {
            main: '#F68B1F',
            light: '#F26921'
        },
    },
    components: {
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: {
                        sm: 10,
                        md: 20,
                        lg: 20
                    }
                }
            }
        }
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <RecoilRoot>
                    <React.Fragment>
                        <MenuBar />
                        {/* <div className='main'>
                            <FormStepper />
                            <ToastContainer/>
                        </div> */}
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center"}}>
                            <FormStepper />
                            <ToastContainer/>
                        </Box>

                    </React.Fragment>
                </RecoilRoot>
            </LocalizationProvider>
        </ThemeProvider>

    );
}

export default App;
