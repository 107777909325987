import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Grid } from "@mui/material";
import ToDoButton from '../components/ToDoButtons';
import { thingsToDoFormState } from '../state';


const ThingsToDoForm = () => {
    const thingsToDo = useRecoilValue(thingsToDoFormState);
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} columns={{ xs: 4, sm: 6, md: 12 }}>
            {thingsToDo.map((item, i) => {
                return (
                    <ToDoButton key={i} primaryColor={item.primaryColor} secondaryColor={item.secondaryColor} text={item.text} value={item.name} />
                );
            })}
        </Grid>
    );
}
export default ThingsToDoForm;