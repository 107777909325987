import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const MenuBar = () => {
    return (
        <AppBar position="sticky"
            sx={{
                backgroundColor: "#000000"
            }}>
            <Container maxWidth="xl" sx={{alignContent: "center", display:"grid", justifyContent: "center"}}>
               
                    <Box
                        component="img"
                        sx={{
                            height: 300,
                            width: 300,
                            maxHeight: { xs: 200, md: 200 },
                            maxWidth: { xs: 200, md: 200 }
                        }} src="/logo.svg">

                    </Box>
            </Container>
        </AppBar>
    );

}
export default MenuBar;