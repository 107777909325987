export const tagsData = 
[
    {
        "name": "American",
        "id": 105,
        "description": "eat"
    },
    {
        "name": "Arts and Heritage",
        "id": 72,
        "description": "experience"
    },
    {
        "name": "BBQ",
        "id": 108,
        "description": "eat"
    },
    {
        "name": "BnB",
        "id": 52,
        "description": "stay"
    },
    {
        "name": "Breakfast Joints",
        "id": 29,
        "description": "eat"
    },
    {
        "name": "Breweries and Distilleries",
        "id": 18,
        "description": "eat"
    },
    {
        "name": "Camping",
        "id": 54,
        "description": "stay"
    },
    {
        "name": "Casual Dining",
        "id": 27,
        "description": "eat"
    },
    {
        "name": "Catering",
        "id": 28,
        "description": "eat"
    },
    {
        "name": "Chinese",
        "id": 102,
        "description": "eat"
    },
    {
        "name": "Coffee and Sweets",
        "id": 30,
        "description": "eat"
    },
    {
        "name": "Culinary",
        "id": 74,
        "description": "experience"
    },
    {
        "name": "Dessert",
        "id": 112,
        "description": "eat"
    },
    {
        "name": "Entertainment",
        "id": 75,
        "description": "experience"
    },
    {
        "name": "Ethnic Favourites",
        "id": 31,
        "description": "eat"
    },
    {
        "name": "Family Friendly",
        "id": 43,
        "description": "eat"
    },
    {
        "name": "Family Fun",
        "id": 76,
        "description": "experience"
    },
    {
        "name": "Farm Fun",
        "id": 77,
        "description": "experience"
    },
    {
        "name": "Fast Food",
        "id": 107,
        "description": "eat"
    },
    {
        "name": "Fine Dining",
        "id": 33,
        "description": "eat"
    },
    {
        "name": "Food and Drink",
        "id": 13,
        "description": "eat"
    },
    {
        "name": "Greek",
        "id": 109,
        "description": "eat"
    },
    {
        "name": "Healthy Options",
        "id": 110,
        "description": "eat"
    },
    {
        "name": "Hostels",
        "id": 51,
        "description": "stay"
    },
    {
        "name": "Hotels",
        "id": 53,
        "description": "stay"
    },
    {
        "name": "Indian",
        "id": 103,
        "description": "eat"
    },
    {
        "name": "Italian",
        "id": 100,
        "description": "eat"
    },
    {
        "name": "Japanese",
        "id": 104,
        "description": "eat"
    },
    {
        "name": "Mexican",
        "id": 101,
        "description": "eat"
    },
    {
        "name": "Nightlife",
        "id": 42,
        "description": "eat"
    },
    {
        "name": "Outdoors and Trails",
        "id": 78,
        "description": "experience"
    },
    {
        "name": "Pizza",
        "id": 111,
        "description": "eat"
    },
    {
        "name": "Pubs",
        "id": 26,
        "description": "eat"
    },
    {
        "name": "Quick Order",
        "id": 44,
        "description": "eat"
    },
    {
        "name": "Relaxation and Beauty",
        "id": 79,
        "description": "experience"
    },
    {
        "name": "See & Do",
        "id": 86,
        "description": "experience"
    },
    {
        "name": "Shopping",
        "id": 81,
        "description": "experience"
    },
    {
        "name": "Sports",
        "id": 80,
        "description": "experience"
    },
    {
        "name": "Stay",
        "id": 50,
        "description": "stay"
    },
    {
        "name": "Thai",
        "id": 106,
        "description": "eat"
    },
    {
        "name": "Tours",
        "id": 82,
        "description": "experience"
    },
    {
        "name": "Vegan/Vegetarian",
        "id": 32,
        "description": "eat"
    },
    {
        "name": "Workshop and Skill Building",
        "id": 83,
        "description": "experience"
    }
]