import * as React from 'react';
import { useRecoilState } from 'recoil';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Link, Typography,Chip } from "@mui/material";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { replaceASCII } from '../util';

const PlaceCard = ({ name, imageSrc, description, slug, address, children, tags }) => {
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                title={<Link href={`https://gatheringuelph.com/${slug}`} underline="always" target="_blank" rel="noopener">
                    <Typography variant='h6'>{replaceASCII(name)}</Typography></Link>}
                subheader={
                    tags?tags.map(_=>(<Chip icon={<LocalOfferIcon />} label={_} variant="outlined" size="medium"/>)): ""
                    
                    }
                
            />
            <CardMedia
                component="img"
                height="194"
                image={imageSrc}
                alt="Paella dish"
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary" sx={{fontStyle: 'italic', fontWeight: 'light' }}>
                    {address}
                </Typography>
                <br></br>
                <Typography sx={{ whiteSpace: "pre-wrap" }} variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
             {children}
            </CardActions>
        </Card>);
}

export default PlaceCard;