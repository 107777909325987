import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Grid, Typography, List, ListItem, Link } from "@mui/material";
import { selectedRecommendationsState } from '../state';
import { replaceASCII } from '../util';


const Results = () => {
    const selectedRecommendations = useRecoilValue(selectedRecommendationsState);
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
            {Object.keys(selectedRecommendations).map(recommendation => {
                return (
                <List sx={{width: '50%'}}>
                    <Typography variant="h5" sx={{ mt: 2, mb: 1 }} align='center' key={recommendation + Date.now()}>
                        {recommendation}
                    </Typography>
                    {selectedRecommendations[recommendation].map((place, i) => {
                        return (
                            <React.Fragment>
                                <ListItem key={i} sx={{ display: "ruby-text" }} aria-label="results list item" >
                                    <Link  variant="body2" sx={{ textAlign: "right", color:"#000" }} href={`https://gatheringuelph.com/${place.slug}`} underline="always" target="_blank" rel="noopener">{replaceASCII(place.name)}</Link>
                                </ListItem>
                            </React.Fragment>);
                    })}
                </List>)
            })}
        </Grid>
    )
}

export default Results;